import React from 'react';
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
    CssBaseline,
    Box,
    Typography,
    Grid,
    Container,
} from "@mui/material";
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ScoreKeeper from "./ScoreKeeper";

const mdTheme = createTheme();

function App() {
  return (
      <ThemeProvider theme={mdTheme}>
          <Box sx={{ display: 'flex' }}>
              <CssBaseline />
              <MuiAppBar>
                  <Toolbar>
                      <Typography
                          component="h1"
                          variant="h6"
                          color="inherit"
                          noWrap
                          sx={{ flexGrow: 1 }}
                      >
                          Toetsanalyse
                      </Typography>
                  </Toolbar>
              </MuiAppBar>
              <Box
                  component="main"
                  sx={{
                      backgroundColor: (theme) =>
                          theme.palette.mode === 'light'
                              ? theme.palette.grey[100]
                              : theme.palette.grey[900],
                      flexGrow: 1,
                      height: '100vh',
                      overflow: 'auto',
                  }}
              >
                  <Toolbar />
                  <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                      <Grid item xs={12} md={8} lg={9}>
                          <ScoreKeeper/>
                      </Grid>
                  </Container>
              </Box>
          </Box>
      </ThemeProvider>
  );
}

export default App;
