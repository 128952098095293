import React, { useState, useRef } from 'react';
import {
    IconButton,
    Paper,
    Snackbar,
    TextField,
    Toolbar
} from "@mui/material";
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import SpeechRecognition, {useSpeechRecognition} from "react-speech-recognition";
import CopyAllIcon from '@mui/icons-material/CopyAll';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Title from "./Title";
import useSound from 'use-sound';

interface CmdControl {
    command: string,
    resetTranscript: () => unknown
}

const ScoreKeeper = () => {
    const [results, setResults] = useState<boolean[]>([])
    const [clipboardToastOpen, setClipboardToastOpen] = React.useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const [playGood] = useSound(
        '/sounds/good.mp3',
        { volume: 0.5 }
    );
    const [playWrong] = useSound(
        '/sounds/wrong.mp3',
        { volume: 0.5 }
    );

    const handleGood = () => {
        setResults(prev => [...prev, true])
        playGood()
    }
    const handleWrong = () => {
        setResults(prev => [...prev, false])
        playWrong()
    }
    const commands = [
        {
            command: ["goed", "hoed"],
            callback: (b: CmdControl) => {console.log("GOED"); handleGood(); b.resetTranscript()},
            matchInterim: true
        },
        {
            command: "fout",
            callback: (b: CmdControl) => {console.log("FOUT"); handleWrong(); b.resetTranscript()},
            matchInterim: true
        },
        {
            command: /.*/i,
            callback: (b: CmdControl) => {b.resetTranscript()}
        }
    ]
    const {
        listening,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition({commands});

    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
    }

    const copyBinary = () => {
        navigator.clipboard.writeText(inputRef.current?.value || "")
        setClipboardToastOpen(true)
    }

    const clear = () => {
        setResults([]);
    }

    return (<Paper
        sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 240
        }}
    >
        <Title>Inspreken resultaten</Title>
        <TextField
            label={"Resultaten"}
            aria-readonly={true}
            value={results.map(toBinary).join("\t")}
            inputRef={inputRef}
            InputProps={{
                endAdornment: (
                    <IconButton edge="end" color="primary" onClick={copyBinary}>
                        <CopyAllIcon />
                    </IconButton>
                ),
            }}
        />
        <Toolbar>
            <IconButton
                color={"primary"}
                disabled={listening}
                title={"Start inspreken"}
                onClick={() => SpeechRecognition.startListening({continuous: true, language: "nl_NL"})}>
                <MicIcon/>
            </IconButton>
            <IconButton
                color={"secondary"}
                disabled={!listening}
                title={"Stop inspreken"}
                onClick={SpeechRecognition.stopListening}>
                <MicOffIcon/>
            </IconButton>
            <IconButton title={"Opnieuw"} color="secondary" onClick={clear}>
                <RestartAltIcon />
            </IconButton>
        </Toolbar>
        <Snackbar
            open={clipboardToastOpen}
            autoHideDuration={6000}
            onClose={() => setClipboardToastOpen(false)}
            message="Resultaten gekopieerd"
        />
    </Paper>)
}

const toBinary = (b: boolean) => b ? "0" : "1"

export default ScoreKeeper;
